import type { mainParams } from '@/interfaces/components';
import type { ResponseType } from '@/interfaces/services';
import errAxiosRes from '@/libs/errRes';
import {
  cvContentValidator,
  scrappingCVSchemaValidator,
  scrappingEmailSchemaValidator,
  scrappingSearchSchemaValidator,
} from '@/libs/valideModules';
import validator from '@/middlewares/validator';
import routes from '@/routes';
import revalidatePaths from '@/utils/revalidateCache';
import type { AxiosInstance } from 'axios';

export const scrappingSearchService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (values: unknown): Promise<ResponseType> => {
    try {
      validator(scrappingSearchSchemaValidator, values as mainParams);
      const { data } = await axios.get(routes.api.scrapSearch(values));

      if (data) {
        revalidatePaths([routes.pages.home()]);
      }
      return [null, data];
    } catch (err: unknown) {
      console.log(err);

      return errAxiosRes(err);
    }
  };

export const scrappingCVService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (values: unknown): Promise<ResponseType> => {
    try {
      validator(scrappingCVSchemaValidator, values as mainParams);
      const { data } = await axios.get(routes.api.scrapCV(values));

      if (data) {
        revalidatePaths([routes.pages.home()]);
      }
      return [null, data];
    } catch (err: unknown) {
      console.log(err);

      return errAxiosRes(err);
    }
  };

export const cvContentService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (values: unknown): Promise<ResponseType> => {
    try {
      validator(cvContentValidator, values as { link: string });
      const { link } = values as { link: string };
      const {
        data: { res },
      } = await axios.get(routes.api.cvContent({ link }));

      return [null, res];
    } catch (err: unknown) {
      console.log(err);

      return errAxiosRes(err);
    }
  };

export const scrappingEmailService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (values: unknown): Promise<ResponseType> => {
    try {
      validator(scrappingEmailSchemaValidator, values as object);
      const {
        data: { res },
      } = await axios.get(routes.api.scrapMail(values));
      return [null, res];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };
