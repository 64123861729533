import type { ResponseType } from '@/interfaces/services';
import { cancelSubType } from '@/interfaces/subscribe';
import errAxiosRes from '@/libs/errRes';
import {
  cancelSubscribeValidator,
  createSubscribeValidator,
} from '@/libs/valideModules';
import validator from '@/middlewares/validator';
import routes from '@/routes';
import type { AxiosInstance } from 'axios';

export const cancelSubscribeService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (props: unknown): Promise<ResponseType> => {
    try {
      const values = props as cancelSubType;

      validator(cancelSubscribeValidator, values);
      const {
        data: { res },
      } = await axios.post(routes.api.cancelSub(), values);

      return [null, res];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };

export const getCurrentSubscribeService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (): Promise<ResponseType> => {
    try {
      const {
        data: { sub },
      } = await axios.get(routes.api.getCurrentSub());

      return [null, sub];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };

export const updateSubscribeService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (props: unknown): Promise<ResponseType> => {
    try {
      validator(cancelSubscribeValidator, props as object);
      const {
        data: { res },
      } = await axios.get(routes.api.updateSub(props));

      return [null, res];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };

export const createSubscribeService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (props: unknown): Promise<ResponseType> => {
    try {
      const values = props as { price_id: string };
      validator(createSubscribeValidator, values);
      const { price_id } = values;
      const {
        data: { res },
      } = await axios.get(routes.api.createSub([price_id]));

      return [null, res];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };

export const getInvoicesService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (): Promise<ResponseType> => {
    try {
      const {
        data: { res },
      } = await axios.get(routes.api.getInvoices());

      return [null, res];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };
