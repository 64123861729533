import type {
  AuthFormType,
  GoogleFormType,
  ResponseType,
} from '@/interfaces/services';
import errAxiosRes from '@/libs/errRes';
import {
  fullLoginSchemaValidator,
  fullRegisterSchemaValidator,
  googleSchemaValidator,
} from '@/libs/valideModules';
import validator from '@/middlewares/validator';
import routes from '@/routes';
import type { AxiosInstance } from 'axios';

export const loginService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (values: unknown): Promise<ResponseType> => {
    try {
      validator(fullLoginSchemaValidator, values as AuthFormType);

      const data = await axios.post(routes.api.login(), values);

      return [null, data];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };

export const registerService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (values: unknown): Promise<ResponseType> => {
    try {
      validator(fullRegisterSchemaValidator, values as AuthFormType);
      const {
        data: { result },
      } = await axios.post(routes.api.register(), values);

      return [null, result];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };

export const googleLoginService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (values: unknown): Promise<ResponseType> => {
    try {
      validator(googleSchemaValidator, values as GoogleFormType);

      const data = await axios.post(routes.api.googleLogin(), values);

      return [null, data];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };
