'use client';
type StorageType = 'local' | 'session';

const haveAccess = typeof window !== 'undefined';

const getStorage = (type: StorageType): Storage => {
  return type === 'local' ? window.localStorage : window.sessionStorage;
};

const setItem = <T>(type: StorageType, key: string, value: T): void => {
  const storage = getStorage(type);
  try {
    storage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(`Failed to set item in ${type}Storage:`, error);
  }
};

const getItem = <T>(type: StorageType, key: string): T | null => {
  const storage = getStorage(type);
  try {
    const item = storage.getItem(key);
    return item ? JSON.parse(item) : null;
  } catch (error) {
    console.error(`Failed to get item from ${type}Storage:`, error);
    return null;
  }
};

const addItem = <T>(type: StorageType, key: string, value: T): void => {
  try {
    const existing = getItem<T[]>(type, key);
    if (existing) {
      existing.push(value);
      setItem(type, key, existing);
    } else {
      setItem(type, key, [value]);
    }
  } catch (error) {
    console.error(`Failed to add item to ${type}Storage:`, error);
  }
};

const storageManager = { addItem, getItem, setItem, haveAccess };
export default storageManager;
