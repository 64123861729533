'use client';

import actions from '@/actions';
import { ClientException } from '@/exceptions';
import type {
  AppContextValues,
  ContextProviderProps,
} from '@/interfaces/providers';
import PrepareServices from '@/services';
import stores from '@/stores';
import React, { createContext, useContext, useMemo } from 'react';

const AppContext = createContext<AppContextValues>(null);

export function AppContextProvider({ children }: ContextProviderProps) {
  const services = PrepareServices();

  const contextValue: any = useMemo(() => {
    return {
      actions,
      services,
      stores,
    };
  }, [services]);

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
}

export default function useAppContext() {
  const context = useContext(AppContext);
  if (!context) {
    throw new ClientException(
      404,
      'useAppContext must be used within an AppContextProvider',
    );
  }
  return context;
}
