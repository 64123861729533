import config from '@/config';
import { ExpiredSessionError, InvalidRoleAccessError } from '@/exceptions';
import axios from 'axios';
const isServer = typeof window === 'undefined';
const AxiosRequest = ({ token }: { token?: string } = {}) => {
  const baseURL = isServer ? config.SSR_URI : process.env.NEXT_PUBLIC_CLI_URI;
  console.log(`${baseURL}/api`);

  return axios.create({
    baseURL: `${baseURL}/api`,
    headers: {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });
};

const AxiosInstance = ({ token }: { token?: string }) => {
  const instance = AxiosRequest({ token });
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 605) {
        throw new InvalidRoleAccessError(error.response?.data?.error);
      }
      if (
        error?.response?.status === 999 &&
        error.response?.data?.error === 'Session expired'
      ) {
        throw new ExpiredSessionError();
      }
      return Promise.reject(error);
    },
  );

  return instance;
};

export default AxiosInstance;
