import type { ResponseType, addScores, getScores } from '@/interfaces/services';
import errAxiosRes from '@/libs/errRes';
import { addingScoreValidator, getScoreValidator } from '@/libs/valideModules';
import validator from '@/middlewares/validator';
import routes from '@/routes';
import revalidatePaths from '@/utils/revalidateCache';
import type { AxiosInstance } from 'axios';

export const AddingScoreService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (props: unknown): Promise<ResponseType> => {
    try {
      validator(addingScoreValidator, props as addScores);
      const {
        data: { res },
      } = await axios.patch(routes.api.addingScore(props));

      if (res) {
        revalidatePaths([routes.pages.home()]);
      }

      return [null, res];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };
export const GetUserScoreService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (props: unknown): Promise<ResponseType> => {
    try {
      const { firstDate, lastDate } = (props || {}) as getScores;
      validator(getScoreValidator, { firstDate, lastDate });

      const {
        data: { res },
      } = await axios.get(routes.api.getScores([firstDate, lastDate]));

      return [null, res];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };
export const GetTotalUserScoreService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (): Promise<ResponseType> => {
    try {
      const {
        data: { res },
      } = await axios.get(routes.api.getTotalScores());

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const replaceInfinityStrings = function (obj: Record<string, any>) {
        for (const key in obj) {
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            replaceInfinityStrings(obj[key]);
          } else if (obj[key] === 'Infinity') {
            obj[key] = Infinity;
          }
        }
      };

      replaceInfinityStrings(res);

      return [null, res];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };
export const GetUserAmountScoreService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (): Promise<ResponseType> => {
    try {
      const {
        data: { res },
      } = await axios.get(routes.api.getAmountScores());

      return [null, res];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };
