import type {
  ConfirmAccountType,
  NewPasswordFormType,
  ResetFormType,
  ResponseType,
  UsersType,
  getUsersType,
} from '@/interfaces/services';
import errAxiosRes from '@/libs/errRes';
import {
  CheckingResetTokenSchemaValidator,
  NewPasswordSchemaValidator,
  ResetPasswordSchemaValidator,
  UserUpdateSchemaValidator,
  confirmAccountSchemaValidator,
  currentUserUpdateSchemaValidator,
  getAllUsersSchemaValidator,
} from '@/libs/valideModules';
import validator from '@/middlewares/validator';
import routes from '@/routes';
import type { AxiosInstance } from 'axios';
import { AxiosError } from 'axios';

export const UpdateCurrentUserService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (values: unknown): Promise<ResponseType> => {
    try {
      validator(currentUserUpdateSchemaValidator, values as ConfirmAccountType);
      const {
        data: { payload },
      } = await axios.put(routes.api.updateCurrentUser(), values);

      return [null, payload];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };

export const UpdateUsersService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (values: unknown): Promise<ResponseType> => {
    try {
      validator(UserUpdateSchemaValidator, values as UsersType);
      const {
        data: { res },
      } = await axios.put(routes.api.updateUsers(), values);

      return [null, res];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };

export const confirmAccountService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (values: unknown): Promise<ResponseType> => {
    try {
      validator(confirmAccountSchemaValidator, values as ConfirmAccountType);
      const {
        data: { res },
      } = await axios.patch(routes.api.confirmAccount(), values);

      return [null, res];
    } catch (err: unknown) {
      const error =
        err instanceof AxiosError
          ? err.response?.data?.error
          : err instanceof Error
            ? err.message
            : 'Something went wrong';
      return Array.isArray(error) ? error : [error];
    }
  };

export const resetPasswordAccountService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (props: unknown): Promise<ResponseType> => {
    try {
      const values = props as ResetFormType;
      validator(ResetPasswordSchemaValidator, values);
      const { email } = values;
      const {
        data: { message },
      } = await axios.get(routes.api.resetPassword([email]));

      return [null, message];
    } catch (err: unknown) {
      const error =
        err instanceof AxiosError
          ? err.response?.data?.error
          : err instanceof Error
            ? err.message
            : 'Something went wrong';
      return Array.isArray(error) ? error : [error];
    }
  };

export const checkingResetTokenService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (props: unknown): Promise<ResponseType> => {
    try {
      const values = props as { token: string };
      validator(CheckingResetTokenSchemaValidator, values);
      const { token } = values;
      const {
        data: { res },
      } = await axios.get(routes.api.checkingReset([token]));

      return [null, res];
    } catch (err: unknown) {
      const error =
        err instanceof AxiosError
          ? err.response?.data?.error
          : err instanceof Error
            ? err.message
            : 'Something went wrong';
      return Array.isArray(error) ? error : [error];
    }
  };

export const NewPasswordAccountService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (values: unknown): Promise<ResponseType> => {
    try {
      validator(NewPasswordSchemaValidator, values as NewPasswordFormType);
      const {
        data: { res },
      } = await axios.patch(routes.api.newPassword(), values);

      return [null, res];
    } catch (err: unknown) {
      const error =
        err instanceof AxiosError
          ? err.response?.data?.error
          : err instanceof Error
            ? err.message
            : 'Something went wrong';
      return Array.isArray(error) ? error : [error];
    }
  };

export const getAllUsersService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (props: unknown): Promise<ResponseType> => {
    try {
      const values = props as getUsersType;

      validator(getAllUsersSchemaValidator, values);
      const {
        data: { res },
      } = await axios.get(routes.api.allUsers(values));

      return [null, res];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };
