import AxiosInstance from '@/app/api/axios';
import { trainingIAService } from './admin';
import { googleLoginService, loginService, registerService } from './auth';
import {
  createFavFolderService,
  getFavFoldersService,
  removeFavFolderService,
} from './favFolders';
import {
  createFavorisService,
  getFavorisService,
  getLastFavorisService,
  removeFavorisService,
  updateFavorisService,
} from './favoris';
import { localisationService } from './localisation';
import {
  AddingScoreService,
  GetTotalUserScoreService,
  GetUserAmountScoreService,
  GetUserScoreService,
} from './scores';
import {
  cvContentService,
  scrappingCVService,
  scrappingEmailService,
  scrappingSearchService,
} from './scrapping';
import {
  createSearchFolderService,
  getSearchFoldersService,
  removeSearchFolderService,
} from './searchFolders';
import {
  createSearchService,
  getSearchesService,
  getTotalSearchesService,
  removeSearchService,
} from './searches';
import {
  cancelSubscribeService,
  createSubscribeService,
  getCurrentSubscribeService,
  getInvoicesService,
  updateSubscribeService,
} from './subscribe';
import {
  NewPasswordAccountService,
  UpdateCurrentUserService,
  UpdateUsersService,
  checkingResetTokenService,
  confirmAccountService,
  getAllUsersService,
  resetPasswordAccountService,
} from './users';

const PrepareServices = ({ token }: { token?: string | undefined } = {}) => {
  const axios = AxiosInstance({ token });
  return {
    //* admin
    trainingIA: trainingIAService({ axios }),
    // * authenticate
    register: registerService({ axios }),
    GoogleLogin: googleLoginService({ axios }),
    login: loginService({ axios }),
    // * users
    confirmAccount: confirmAccountService({ axios }),
    resetPassword: resetPasswordAccountService({ axios }),
    checkingReset: checkingResetTokenService({ axios }),
    newPassword: NewPasswordAccountService({ axios }),
    updateCurrentUser: UpdateCurrentUserService({ axios }),
    updateUsers: UpdateUsersService({ axios }),
    getAllUsers: getAllUsersService({ axios }),
    // * subscribe
    getCurrentSubscribe: getCurrentSubscribeService({ axios }),
    cancellationSub: cancelSubscribeService({ axios }),
    updateSubscribe: updateSubscribeService({ axios }),
    createSubscribe: createSubscribeService({ axios }),
    getInvoices: getInvoicesService({ axios }),
    // * scores
    addingScore: AddingScoreService({ axios }),
    getUserScore: GetUserScoreService({ axios }),
    getUserAmountScore: GetUserAmountScoreService({ axios }),
    GetTotalUserScore: GetTotalUserScoreService({ axios }),
    // * favoris
    addFavori: createFavorisService({ axios }),
    updateFav: updateFavorisService({ axios }),
    removeFavori: removeFavorisService({ axios }),
    getFavoris: getFavorisService({ axios }),
    getLastFavoris: getLastFavorisService({ axios }),
    // * FavFolders
    addFavFolder: createFavFolderService({ axios }),
    getFavFolders: getFavFoldersService({ axios }),
    removeFavFolder: removeFavFolderService({ axios }),
    // * SearchFolders
    addSearchFolder: createSearchFolderService({ axios }),
    getSearchFolder: getSearchFoldersService({ axios }),
    removeSearchFolder: removeSearchFolderService({ axios }),
    // * Searches
    addSearch: createSearchService({ axios }),
    removeSearch: removeSearchService({ axios }),
    getSearches: getSearchesService({ axios }),
    getTotalSearches: getTotalSearchesService({ axios }),
    // * scrapping
    scrappingSearch: scrappingSearchService({ axios }),
    scrappingCV: scrappingCVService({ axios }),
    scrappingEmail: scrappingEmailService({ axios }),
    cvContent: cvContentService({ axios }),
    // * apiCallback
    localisation: localisationService(),
  };
};

export default PrepareServices;
