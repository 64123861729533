import type { ResponseType } from '@/interfaces/services';
import { createSearchFolderType } from '@/interfaces/validation';
import errAxiosRes from '@/libs/errRes';
import {
  createSearchFolderService,
  getSearchSchemaValidator,
  getTotalSearchSchemaValidator,
  removeSearchSchemaValidator,
} from '@/libs/valideModules';
import validator from '@/middlewares/validator';
import routes from '@/routes';
import revalidatePaths from '@/utils/revalidateCache';
import type { AxiosInstance } from 'axios';

export const createSearchService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (props: unknown): Promise<ResponseType> => {
    try {
      const values = props as createSearchFolderType;

      validator(createSearchFolderService, values);
      const { search, ...other } = values;
      const {
        data: { res },
      } = await axios.post(routes.api.addSearch(other), { search });

      if (res) {
        revalidatePaths([routes.pages.searches()]);
      }

      return [null, res];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };

export const removeSearchService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (values: unknown): Promise<ResponseType> => {
    try {
      validator(removeSearchSchemaValidator, values as object);
      const { id } = values as { id: [] };
      const {
        data: { res },
      } = await axios.delete(routes.api.removeSearch(id));

      if (res) {
        revalidatePaths([routes.pages.home()]);
      }

      return [null, res];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };

export const getSearchesService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (values: unknown): Promise<ResponseType> => {
    try {
      validator(getSearchSchemaValidator, values as object);
      const {
        data: { res },
      } = await axios.get(routes.api.getSearches(values));

      return [null, res];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };

export const getTotalSearchesService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (value: unknown): Promise<ResponseType> => {
    try {
      validator(getTotalSearchSchemaValidator, value as object);

      const {
        data: { res },
      } = await axios.get(
        routes.api.getTotalSearches(
          Object.values(value as object).map((v) => v?.toString()),
        ),
      );

      return [null, res];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };
