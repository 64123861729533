import { ExpiredSessionError, InvalidRoleAccessError } from '@/exceptions';
import routes from '@/routes';
import { AxiosError } from 'axios';
import { redirect } from 'next/navigation';

export default function errAxiosRes(err: unknown) {
  if (err instanceof ExpiredSessionError) {
    redirect(routes.pages.signOut());
  }
  const error =
    (err instanceof AxiosError
      ? err.response?.data?.error
      : (err as Error).message) ?? "Une erreur s'est produite";

  if (
    err instanceof InvalidRoleAccessError &&
    error !== "Une erreur s'est produite"
  ) {
    return [true, { plan: error }];
  }
  return Array.isArray(error) ? error : [error];
}
