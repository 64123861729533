import type { ResponseType, Zone } from '@/interfaces/services';
import errAxiosRes from '@/libs/errRes';
import { localisationValidator } from '@/libs/valideModules';
import validator from '@/middlewares/validator';
import routes from '@/routes';
import axios from 'axios';

const routeDir: Record<string, string> = {
  Nc: 'communeCodeLoc',
  c: 'communeNameLoc',
  Nr: 'regionCodeLoc',
  r: 'regionNameLoc',
};

export const localisationService =
  () =>
  async (values: unknown): Promise<ResponseType> => {
    try {
      validator(localisationValidator, values as Zone);
      const routeName: string =
        routeDir[
          `${typeof (values as Zone).search === 'number' ? 'N' : ''}${(values as Zone).zone.charAt(0).toLowerCase()}`
        ];

      const { data } = await axios.get(routes.api[routeName](values as Zone));

      return [null, data];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };
