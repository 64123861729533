import type { ResponseType } from '@/interfaces/services';
import errAxiosRes from '@/libs/errRes';
import {
  createFavorisSchemaValidator,
  getFavorisSchemaValidator,
  getLastFavorisSchemaValidator,
  removeFavorisSchemaValidator,
  updateFavorisSchemaValidator,
} from '@/libs/valideModules';
import validator from '@/middlewares/validator';
import routes from '@/routes';
import revalidatePaths from '@/utils/revalidateCache';
import type { AxiosInstance } from 'axios';

export const createFavorisService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (values: unknown): Promise<ResponseType> => {
    try {
      validator(createFavorisSchemaValidator, values as object);
      const {
        data: { res },
      } = await axios.post(routes.api.addFavori(), values);

      if (res) {
        revalidatePaths([
          routes.pages.home(),
          routes.pages.pro(),
          routes.pages.cv(),
        ]);
      }

      return [null, res];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };
export const updateFavorisService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (values: unknown): Promise<ResponseType> => {
    try {
      validator(updateFavorisSchemaValidator, values as object);
      const {
        data: { res },
      } = await axios.put(routes.api.updateFavori(), values);

      if (res) {
        revalidatePaths([
          routes.pages.home(),
          routes.pages.pro(),
          routes.pages.cv(),
        ]);
      }

      return [null, res];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };

export const removeFavorisService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (values: unknown): Promise<ResponseType> => {
    const props = values as { id: number };
    try {
      validator(removeFavorisSchemaValidator, props);
      const {
        data: { res },
      } = await axios.delete(routes.api.removeFavoris([props.id]));

      if (res) {
        revalidatePaths([
          routes.pages.home(),
          routes.pages.pro(),
          routes.pages.cv(),
        ]);
      }

      return [null, res];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };

export const getFavorisService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (values: unknown): Promise<ResponseType> => {
    try {
      validator(getFavorisSchemaValidator, values as object);
      const {
        data: { res },
      } = await axios.get(routes.api.getFavoris(values));

      return [null, res];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };

export const getLastFavorisService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (values: unknown): Promise<ResponseType> => {
    try {
      validator(getLastFavorisSchemaValidator, values as object);
      const {
        data: { res },
      } = await axios.get(routes.api.getLastFavoris(values));

      return [null, res];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };
