import type { ResponseType } from '@/interfaces/services';
import errAxiosRes from '@/libs/errRes';
import {
  createFolderSchemaValidator,
  getFolderSchemaValidator,
  removeFolderSchemaValidator,
} from '@/libs/valideModules';
import validator from '@/middlewares/validator';
import routes from '@/routes';
import revalidatePaths from '@/utils/revalidateCache';
import type { AxiosInstance } from 'axios';

export const createFavFolderService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (values: unknown): Promise<ResponseType> => {
    try {
      validator(createFolderSchemaValidator, values as object);
      const {
        data: { res },
      } = await axios.post(routes.api.addFavFolder(), values);

      if (res) {
        revalidatePaths([routes.pages.favoris()]);
      }

      return [null, res];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };

export const removeFavFolderService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (values: unknown): Promise<ResponseType> => {
    try {
      validator(removeFolderSchemaValidator, values as object);
      const { id } = values as { id: [] };
      const {
        data: { res },
      } = await axios.delete(routes.api.removeFavFolder(id));

      if (res) {
        revalidatePaths([
          routes.pages.home(),
          routes.pages.pro(),
          routes.pages.cv(),
        ]);
      }

      return [null, res];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };

export const getFavFoldersService =
  ({ axios }: { axios: AxiosInstance }) =>
  async (values: unknown): Promise<ResponseType> => {
    try {
      validator(getFolderSchemaValidator, values as object);
      const {
        data: { res },
      } = await axios.get(routes.api.getFavFolders(values));

      return [null, res];
    } catch (err: unknown) {
      return errAxiosRes(err);
    }
  };
