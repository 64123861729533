import { mainParams } from '@/interfaces/components';
import {
  initializedStorage,
  scrappingDataStorage,
  scrappingInfos,
  scrappingStorage,
  trainingStorage,
} from '@/interfaces/scrapping';
import { pageType } from '@/interfaces/services';
import storageManager from '@/libs/storage';

const getSearch = (pageType?: pageType): initializedStorage | undefined => {
  if (!pageType) {
    return undefined;
  }
  try {
    const store = storageManager.getItem<scrappingStorage>(
      'session',
      'candidat_by_Talaryo',
    );
    if (!store) {
      return undefined;
    }
    const filteredPages = store.pages?.filter(
      (v) => v !== null && (pageType === 'pro' ? 'link' in v : 'pdf' in v),
    );

    return {
      pages: filteredPages,
      data: store[pageType]?.data as scrappingDataStorage,
    };
  } catch (error) {
    console.error('Failed to get search from sessionStorage:', error);
    return undefined;
  }
};

const getTrain = (): trainingStorage | null => {
  try {
    return storageManager.getItem<trainingStorage>('session', 'trainingIa');
  } catch (error) {
    return null;
  }
};

const getAllProfiles = (pageType?: pageType): scrappingInfos[] => {
  try {
    const store = storageManager.getItem<scrappingStorage>(
      'session',
      'candidat_by_Talaryo',
    );
    if (!store || !store.pages) {
      return [];
    }
    return (
      store.pages.filter(
        (v) => v && (pageType === 'pro' ? 'link' in v : 'pdf' in v),
      ) || []
    );
  } catch (error) {
    console.error('Failed to get all profiles from sessionStorage:', error);
    return [];
  }
};

const getSearchName = (search: mainParams): string => {
  const { platform, fn } = search;
  return `Talaryo-${platform ? platform : 'CV'}${fn ? `-${fn}` : ''}`;
};

const stores = {
  getSearch,
  getAllProfiles,
  getSearchName,
  getTrain,
};

export default stores;
